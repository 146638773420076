import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const hasPermission = (permissions, permission) => {
  if (typeof permission === 'string') {
    return permissions.includes(permission)
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true
      }
    }
    return false
  }
}

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/lock.jpg'),
    drawer: null,
    rolesAndPermissions: {
      newPermissionAdded: false,
      permissions: [],
    },
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_NEW_PERMISSION_ADDED(state, payload) {
      state.rolesAndPermissions.newPermissionAdded = payload
    },
    SET_PERMISSIONS_ARRAY(state, payload) {
      state.rolesAndPermissions.permissions = payload
    },
    LOGOUT(state) {
      state.loggedInUser = null
    },
  },
  actions: {},
  getters: {
    menuHasPermission: (state) => (menu) => {
      if (menu.permission) {
        if (!state.loggedInUser) {
          const loggedInUser = localStorage.getItem('loggedInUser')
          if (!loggedInUser) {
            return false
          }
          state.loggedInUser = JSON.parse(loggedInUser)
        }
        return hasPermission(state.loggedInUser.permissions, menu.permission)
      }

      return true
    },
    userhasPermission: (state) => (permission) => {
      if (!state.loggedInUser) {
        const loggedInUser = localStorage.getItem('loggedInUser')
        if (!loggedInUser) {
          return false
        }
        state.loggedInUser = JSON.parse(loggedInUser)
      }
      return hasPermission(state.loggedInUser.permissions, permission)
    },
    getPermissionsArray: (state) => {
      const data = {}
      state.rolesAndPermissions.permissions.map(
        (e) => (data[e.id] = { name: e.name, guard_name: e.guard_name })
      )
      return data
    },
  },
})
