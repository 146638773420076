import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/bank/validate/:bank",
      name: "Bank validate",
      component: () => import("@/views/bank/Validate"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/Login"),
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/dashboard/Dashboard"),
        },
        // Pages
        {
          name: "User Profile",
          path: "pages/user",
          component: () => import("@/views/pages/admins/UserProfile"),
        },
        {
          name: "Administrators",
          path: "pages/admins",
          meta: {
            permission: "edit admins",
          },
          component: () => import("@/views/pages/admins/UsersTable"),
        },
        {
          name: "Notifications",
          path: "pages/notifications",
          component: () => import("@/views/pages/users/Notifications"),
        },
        {
          name: "Settings",
          path: "pages/settings-page",
          component: () => import("@/views/pages/settings/SettingsPage"),
        },
        {
          name: "Transactions List",
          path: "pages/transactions-list",
          component: () =>
            import("@/views/pages/transactions/TransactionsList"),
        },
        {
          name: "Fees List",
          path: "pages/fees-list",
          component: () => import("@/views/pages/bookings/FeesList"),
        },
        {
          name: "User Transactions List",
          path: "pages/users/:user_id/transactions",
          component: () =>
            import("@/views/pages/transactions/TransactionsList"),
        },
        {
          name: "User Bookings List",
          path: "pages/users/:user_id/bookings",
          component: () => import("@/views/pages/bookings/BookingsList"),
        },
        {
          name: "User Subscriptions List",
          path: "pages/users/:user_id/subscriptions",
          component: () =>
            import("@/views/pages/transactions/TransactionsList"),
        },
        {
          name: "Identities List",
          path: "pages/kyc/identities-list/",
          component: () => import("@/views/pages/kyc/IdentitiesList"),
        },
        {
          name: "User Profile",
          path: "pages/users/:id",
          component: () => import("@/views/pages/users/Profile"),
        },
        {
          name: "Roles And Permissions",
          path: "/pages/roles",
          meta: {
            permission: "edit roles",
          },
          component: () =>
            import(
              "@/views/pages/admins/roles-permissions/RolesAndPermissions"
            ),
        },
        {
          name: "Banks",
          path: "/pages/banks",
          component: () => import("@/views/pages/banks/Banks"),
        },
        {
          name: "Bank transfers",
          path: "/pages/bank/transfers",
          meta: {
            permission: "bank transfers",
          },
          component: () => import("@/views/pages/bank-transfers/BankTransfers"),
        },
        {
          name: "Debug routes",
          path: "/debug/routes",
          component: () => import("@/views/debug/Routes"),
        },
        {
          name: "Bookings",
          path: "/pages/bookings-list",
          component: () => import("@/views/pages/bookings/BookingsList"),
        },
        {
          name: "Debug route",
          path: "/debug/routes/:method/:uri",
          component: () => import("@/views/debug/Route"),
        },
        {
          name: "Users list",
          path: "/pages/user-list",
          meta: {
            permission: "edit users",
          },
          component: () => import("@/views/pages/users/UsersList.vue"),
        },
      ],
    },
  ],
});

function hasPermission(permissions, permission) {
  if (typeof permission === "string") {
    return permissions.includes(permission);
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true;
      }
    }
    return false;
  }
}

router.beforeEach((to, from, next) => {
  let loggedInUser = localStorage.getItem("loggedInUser");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!loggedInUser) {
      const loginpath = window.location.pathname;
      next({ name: "Login", query: { from: loginpath } });
    } else if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser);
      if (
        to.meta.permission &&
        !hasPermission(loggedInUser.permissions, to.meta.permission)
      ) {
        next(from);
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
